/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.pictophone.v0_1.ActionRequest', null, global);
goog.exportSymbol('proto.pictophone.v0_1.ActionResponse', null, global);
goog.exportSymbol('proto.pictophone.v0_1.EmptyHandError', null, global);
goog.exportSymbol('proto.pictophone.v0_1.Game', null, global);
goog.exportSymbol('proto.pictophone.v0_1.Game.Started', null, global);
goog.exportSymbol('proto.pictophone.v0_1.Game.Unstarted', null, global);
goog.exportSymbol('proto.pictophone.v0_1.GameAlreadyStartedError', null, global);
goog.exportSymbol('proto.pictophone.v0_1.GameNotStartedError', null, global);
goog.exportSymbol('proto.pictophone.v0_1.GetGameRequest', null, global);
goog.exportSymbol('proto.pictophone.v0_1.GetGameResponse', null, global);
goog.exportSymbol('proto.pictophone.v0_1.JoinGameRequest', null, global);
goog.exportSymbol('proto.pictophone.v0_1.JoinGameResponse', null, global);
goog.exportSymbol('proto.pictophone.v0_1.MakeMoveRequest', null, global);
goog.exportSymbol('proto.pictophone.v0_1.MakeMoveResponse', null, global);
goog.exportSymbol('proto.pictophone.v0_1.MoveAbortedError', null, global);
goog.exportSymbol('proto.pictophone.v0_1.PlayerNotInGameError', null, global);
goog.exportSymbol('proto.pictophone.v0_1.QueryRequest', null, global);
goog.exportSymbol('proto.pictophone.v0_1.QueryResponse', null, global);
goog.exportSymbol('proto.pictophone.v0_1.StartGameRequest', null, global);
goog.exportSymbol('proto.pictophone.v0_1.StartGameResponse', null, global);
goog.exportSymbol('proto.pictophone.v0_1.UnknownError', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pictophone.v0_1.ActionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.pictophone.v0_1.ActionRequest.oneofGroups_);
};
goog.inherits(proto.pictophone.v0_1.ActionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.pictophone.v0_1.ActionRequest.displayName = 'proto.pictophone.v0_1.ActionRequest';
}
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.pictophone.v0_1.ActionRequest.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.pictophone.v0_1.ActionRequest.MethodCase = {
  METHOD_NOT_SET: 0,
  JOIN_GAME_REQUEST: 1,
  START_GAME_REQUEST: 2,
  MAKE_MOVE_REQUEST: 3
};

/**
 * @return {proto.pictophone.v0_1.ActionRequest.MethodCase}
 */
proto.pictophone.v0_1.ActionRequest.prototype.getMethodCase = function() {
  return /** @type {proto.pictophone.v0_1.ActionRequest.MethodCase} */(jspb.Message.computeOneofCase(this, proto.pictophone.v0_1.ActionRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pictophone.v0_1.ActionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.pictophone.v0_1.ActionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pictophone.v0_1.ActionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.ActionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    joinGameRequest: (f = msg.getJoinGameRequest()) && proto.pictophone.v0_1.JoinGameRequest.toObject(includeInstance, f),
    startGameRequest: (f = msg.getStartGameRequest()) && proto.pictophone.v0_1.StartGameRequest.toObject(includeInstance, f),
    makeMoveRequest: (f = msg.getMakeMoveRequest()) && proto.pictophone.v0_1.MakeMoveRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pictophone.v0_1.ActionRequest}
 */
proto.pictophone.v0_1.ActionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pictophone.v0_1.ActionRequest;
  return proto.pictophone.v0_1.ActionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pictophone.v0_1.ActionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pictophone.v0_1.ActionRequest}
 */
proto.pictophone.v0_1.ActionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pictophone.v0_1.JoinGameRequest;
      reader.readMessage(value,proto.pictophone.v0_1.JoinGameRequest.deserializeBinaryFromReader);
      msg.setJoinGameRequest(value);
      break;
    case 2:
      var value = new proto.pictophone.v0_1.StartGameRequest;
      reader.readMessage(value,proto.pictophone.v0_1.StartGameRequest.deserializeBinaryFromReader);
      msg.setStartGameRequest(value);
      break;
    case 3:
      var value = new proto.pictophone.v0_1.MakeMoveRequest;
      reader.readMessage(value,proto.pictophone.v0_1.MakeMoveRequest.deserializeBinaryFromReader);
      msg.setMakeMoveRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pictophone.v0_1.ActionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pictophone.v0_1.ActionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pictophone.v0_1.ActionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.ActionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJoinGameRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pictophone.v0_1.JoinGameRequest.serializeBinaryToWriter
    );
  }
  f = message.getStartGameRequest();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pictophone.v0_1.StartGameRequest.serializeBinaryToWriter
    );
  }
  f = message.getMakeMoveRequest();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pictophone.v0_1.MakeMoveRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional JoinGameRequest join_game_request = 1;
 * @return {?proto.pictophone.v0_1.JoinGameRequest}
 */
proto.pictophone.v0_1.ActionRequest.prototype.getJoinGameRequest = function() {
  return /** @type{?proto.pictophone.v0_1.JoinGameRequest} */ (
    jspb.Message.getWrapperField(this, proto.pictophone.v0_1.JoinGameRequest, 1));
};


/** @param {?proto.pictophone.v0_1.JoinGameRequest|undefined} value */
proto.pictophone.v0_1.ActionRequest.prototype.setJoinGameRequest = function(value) {
  jspb.Message.setOneofWrapperField(this, 1, proto.pictophone.v0_1.ActionRequest.oneofGroups_[0], value);
};


proto.pictophone.v0_1.ActionRequest.prototype.clearJoinGameRequest = function() {
  this.setJoinGameRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pictophone.v0_1.ActionRequest.prototype.hasJoinGameRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StartGameRequest start_game_request = 2;
 * @return {?proto.pictophone.v0_1.StartGameRequest}
 */
proto.pictophone.v0_1.ActionRequest.prototype.getStartGameRequest = function() {
  return /** @type{?proto.pictophone.v0_1.StartGameRequest} */ (
    jspb.Message.getWrapperField(this, proto.pictophone.v0_1.StartGameRequest, 2));
};


/** @param {?proto.pictophone.v0_1.StartGameRequest|undefined} value */
proto.pictophone.v0_1.ActionRequest.prototype.setStartGameRequest = function(value) {
  jspb.Message.setOneofWrapperField(this, 2, proto.pictophone.v0_1.ActionRequest.oneofGroups_[0], value);
};


proto.pictophone.v0_1.ActionRequest.prototype.clearStartGameRequest = function() {
  this.setStartGameRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pictophone.v0_1.ActionRequest.prototype.hasStartGameRequest = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional MakeMoveRequest make_move_request = 3;
 * @return {?proto.pictophone.v0_1.MakeMoveRequest}
 */
proto.pictophone.v0_1.ActionRequest.prototype.getMakeMoveRequest = function() {
  return /** @type{?proto.pictophone.v0_1.MakeMoveRequest} */ (
    jspb.Message.getWrapperField(this, proto.pictophone.v0_1.MakeMoveRequest, 3));
};


/** @param {?proto.pictophone.v0_1.MakeMoveRequest|undefined} value */
proto.pictophone.v0_1.ActionRequest.prototype.setMakeMoveRequest = function(value) {
  jspb.Message.setOneofWrapperField(this, 3, proto.pictophone.v0_1.ActionRequest.oneofGroups_[0], value);
};


proto.pictophone.v0_1.ActionRequest.prototype.clearMakeMoveRequest = function() {
  this.setMakeMoveRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pictophone.v0_1.ActionRequest.prototype.hasMakeMoveRequest = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pictophone.v0_1.ActionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.pictophone.v0_1.ActionResponse.oneofGroups_);
};
goog.inherits(proto.pictophone.v0_1.ActionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.pictophone.v0_1.ActionResponse.displayName = 'proto.pictophone.v0_1.ActionResponse';
}
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.pictophone.v0_1.ActionResponse.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.pictophone.v0_1.ActionResponse.MethodCase = {
  METHOD_NOT_SET: 0,
  JOIN_GAME_RESPONSE: 1,
  START_GAME_RESPONSE: 2,
  MAKE_MOVE_RESPONSE: 3
};

/**
 * @return {proto.pictophone.v0_1.ActionResponse.MethodCase}
 */
proto.pictophone.v0_1.ActionResponse.prototype.getMethodCase = function() {
  return /** @type {proto.pictophone.v0_1.ActionResponse.MethodCase} */(jspb.Message.computeOneofCase(this, proto.pictophone.v0_1.ActionResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pictophone.v0_1.ActionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.pictophone.v0_1.ActionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pictophone.v0_1.ActionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.ActionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    joinGameResponse: (f = msg.getJoinGameResponse()) && proto.pictophone.v0_1.JoinGameResponse.toObject(includeInstance, f),
    startGameResponse: (f = msg.getStartGameResponse()) && proto.pictophone.v0_1.StartGameResponse.toObject(includeInstance, f),
    makeMoveResponse: (f = msg.getMakeMoveResponse()) && proto.pictophone.v0_1.MakeMoveResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pictophone.v0_1.ActionResponse}
 */
proto.pictophone.v0_1.ActionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pictophone.v0_1.ActionResponse;
  return proto.pictophone.v0_1.ActionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pictophone.v0_1.ActionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pictophone.v0_1.ActionResponse}
 */
proto.pictophone.v0_1.ActionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pictophone.v0_1.JoinGameResponse;
      reader.readMessage(value,proto.pictophone.v0_1.JoinGameResponse.deserializeBinaryFromReader);
      msg.setJoinGameResponse(value);
      break;
    case 2:
      var value = new proto.pictophone.v0_1.StartGameResponse;
      reader.readMessage(value,proto.pictophone.v0_1.StartGameResponse.deserializeBinaryFromReader);
      msg.setStartGameResponse(value);
      break;
    case 3:
      var value = new proto.pictophone.v0_1.MakeMoveResponse;
      reader.readMessage(value,proto.pictophone.v0_1.MakeMoveResponse.deserializeBinaryFromReader);
      msg.setMakeMoveResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pictophone.v0_1.ActionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pictophone.v0_1.ActionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pictophone.v0_1.ActionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.ActionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJoinGameResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pictophone.v0_1.JoinGameResponse.serializeBinaryToWriter
    );
  }
  f = message.getStartGameResponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pictophone.v0_1.StartGameResponse.serializeBinaryToWriter
    );
  }
  f = message.getMakeMoveResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pictophone.v0_1.MakeMoveResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional JoinGameResponse join_game_response = 1;
 * @return {?proto.pictophone.v0_1.JoinGameResponse}
 */
proto.pictophone.v0_1.ActionResponse.prototype.getJoinGameResponse = function() {
  return /** @type{?proto.pictophone.v0_1.JoinGameResponse} */ (
    jspb.Message.getWrapperField(this, proto.pictophone.v0_1.JoinGameResponse, 1));
};


/** @param {?proto.pictophone.v0_1.JoinGameResponse|undefined} value */
proto.pictophone.v0_1.ActionResponse.prototype.setJoinGameResponse = function(value) {
  jspb.Message.setOneofWrapperField(this, 1, proto.pictophone.v0_1.ActionResponse.oneofGroups_[0], value);
};


proto.pictophone.v0_1.ActionResponse.prototype.clearJoinGameResponse = function() {
  this.setJoinGameResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pictophone.v0_1.ActionResponse.prototype.hasJoinGameResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StartGameResponse start_game_response = 2;
 * @return {?proto.pictophone.v0_1.StartGameResponse}
 */
proto.pictophone.v0_1.ActionResponse.prototype.getStartGameResponse = function() {
  return /** @type{?proto.pictophone.v0_1.StartGameResponse} */ (
    jspb.Message.getWrapperField(this, proto.pictophone.v0_1.StartGameResponse, 2));
};


/** @param {?proto.pictophone.v0_1.StartGameResponse|undefined} value */
proto.pictophone.v0_1.ActionResponse.prototype.setStartGameResponse = function(value) {
  jspb.Message.setOneofWrapperField(this, 2, proto.pictophone.v0_1.ActionResponse.oneofGroups_[0], value);
};


proto.pictophone.v0_1.ActionResponse.prototype.clearStartGameResponse = function() {
  this.setStartGameResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pictophone.v0_1.ActionResponse.prototype.hasStartGameResponse = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional MakeMoveResponse make_move_response = 3;
 * @return {?proto.pictophone.v0_1.MakeMoveResponse}
 */
proto.pictophone.v0_1.ActionResponse.prototype.getMakeMoveResponse = function() {
  return /** @type{?proto.pictophone.v0_1.MakeMoveResponse} */ (
    jspb.Message.getWrapperField(this, proto.pictophone.v0_1.MakeMoveResponse, 3));
};


/** @param {?proto.pictophone.v0_1.MakeMoveResponse|undefined} value */
proto.pictophone.v0_1.ActionResponse.prototype.setMakeMoveResponse = function(value) {
  jspb.Message.setOneofWrapperField(this, 3, proto.pictophone.v0_1.ActionResponse.oneofGroups_[0], value);
};


proto.pictophone.v0_1.ActionResponse.prototype.clearMakeMoveResponse = function() {
  this.setMakeMoveResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pictophone.v0_1.ActionResponse.prototype.hasMakeMoveResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pictophone.v0_1.QueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.pictophone.v0_1.QueryRequest.oneofGroups_);
};
goog.inherits(proto.pictophone.v0_1.QueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.pictophone.v0_1.QueryRequest.displayName = 'proto.pictophone.v0_1.QueryRequest';
}
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.pictophone.v0_1.QueryRequest.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.pictophone.v0_1.QueryRequest.MethodCase = {
  METHOD_NOT_SET: 0,
  GET_GAME_REQUEST: 1
};

/**
 * @return {proto.pictophone.v0_1.QueryRequest.MethodCase}
 */
proto.pictophone.v0_1.QueryRequest.prototype.getMethodCase = function() {
  return /** @type {proto.pictophone.v0_1.QueryRequest.MethodCase} */(jspb.Message.computeOneofCase(this, proto.pictophone.v0_1.QueryRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pictophone.v0_1.QueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.pictophone.v0_1.QueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pictophone.v0_1.QueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.QueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    getGameRequest: (f = msg.getGetGameRequest()) && proto.pictophone.v0_1.GetGameRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pictophone.v0_1.QueryRequest}
 */
proto.pictophone.v0_1.QueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pictophone.v0_1.QueryRequest;
  return proto.pictophone.v0_1.QueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pictophone.v0_1.QueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pictophone.v0_1.QueryRequest}
 */
proto.pictophone.v0_1.QueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pictophone.v0_1.GetGameRequest;
      reader.readMessage(value,proto.pictophone.v0_1.GetGameRequest.deserializeBinaryFromReader);
      msg.setGetGameRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pictophone.v0_1.QueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pictophone.v0_1.QueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pictophone.v0_1.QueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.QueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGetGameRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pictophone.v0_1.GetGameRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetGameRequest get_game_request = 1;
 * @return {?proto.pictophone.v0_1.GetGameRequest}
 */
proto.pictophone.v0_1.QueryRequest.prototype.getGetGameRequest = function() {
  return /** @type{?proto.pictophone.v0_1.GetGameRequest} */ (
    jspb.Message.getWrapperField(this, proto.pictophone.v0_1.GetGameRequest, 1));
};


/** @param {?proto.pictophone.v0_1.GetGameRequest|undefined} value */
proto.pictophone.v0_1.QueryRequest.prototype.setGetGameRequest = function(value) {
  jspb.Message.setOneofWrapperField(this, 1, proto.pictophone.v0_1.QueryRequest.oneofGroups_[0], value);
};


proto.pictophone.v0_1.QueryRequest.prototype.clearGetGameRequest = function() {
  this.setGetGameRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pictophone.v0_1.QueryRequest.prototype.hasGetGameRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pictophone.v0_1.QueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.pictophone.v0_1.QueryResponse.oneofGroups_);
};
goog.inherits(proto.pictophone.v0_1.QueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.pictophone.v0_1.QueryResponse.displayName = 'proto.pictophone.v0_1.QueryResponse';
}
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.pictophone.v0_1.QueryResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.pictophone.v0_1.QueryResponse.MethodCase = {
  METHOD_NOT_SET: 0,
  GET_GAME_RESPONSE: 1
};

/**
 * @return {proto.pictophone.v0_1.QueryResponse.MethodCase}
 */
proto.pictophone.v0_1.QueryResponse.prototype.getMethodCase = function() {
  return /** @type {proto.pictophone.v0_1.QueryResponse.MethodCase} */(jspb.Message.computeOneofCase(this, proto.pictophone.v0_1.QueryResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pictophone.v0_1.QueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.pictophone.v0_1.QueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pictophone.v0_1.QueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.QueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    getGameResponse: (f = msg.getGetGameResponse()) && proto.pictophone.v0_1.GetGameResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pictophone.v0_1.QueryResponse}
 */
proto.pictophone.v0_1.QueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pictophone.v0_1.QueryResponse;
  return proto.pictophone.v0_1.QueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pictophone.v0_1.QueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pictophone.v0_1.QueryResponse}
 */
proto.pictophone.v0_1.QueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pictophone.v0_1.GetGameResponse;
      reader.readMessage(value,proto.pictophone.v0_1.GetGameResponse.deserializeBinaryFromReader);
      msg.setGetGameResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pictophone.v0_1.QueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pictophone.v0_1.QueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pictophone.v0_1.QueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.QueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGetGameResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pictophone.v0_1.GetGameResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetGameResponse get_game_response = 1;
 * @return {?proto.pictophone.v0_1.GetGameResponse}
 */
proto.pictophone.v0_1.QueryResponse.prototype.getGetGameResponse = function() {
  return /** @type{?proto.pictophone.v0_1.GetGameResponse} */ (
    jspb.Message.getWrapperField(this, proto.pictophone.v0_1.GetGameResponse, 1));
};


/** @param {?proto.pictophone.v0_1.GetGameResponse|undefined} value */
proto.pictophone.v0_1.QueryResponse.prototype.setGetGameResponse = function(value) {
  jspb.Message.setOneofWrapperField(this, 1, proto.pictophone.v0_1.QueryResponse.oneofGroups_[0], value);
};


proto.pictophone.v0_1.QueryResponse.prototype.clearGetGameResponse = function() {
  this.setGetGameResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pictophone.v0_1.QueryResponse.prototype.hasGetGameResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pictophone.v0_1.JoinGameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pictophone.v0_1.JoinGameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.pictophone.v0_1.JoinGameRequest.displayName = 'proto.pictophone.v0_1.JoinGameRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pictophone.v0_1.JoinGameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.pictophone.v0_1.JoinGameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pictophone.v0_1.JoinGameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.JoinGameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    gameId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    playerId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pictophone.v0_1.JoinGameRequest}
 */
proto.pictophone.v0_1.JoinGameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pictophone.v0_1.JoinGameRequest;
  return proto.pictophone.v0_1.JoinGameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pictophone.v0_1.JoinGameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pictophone.v0_1.JoinGameRequest}
 */
proto.pictophone.v0_1.JoinGameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pictophone.v0_1.JoinGameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pictophone.v0_1.JoinGameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pictophone.v0_1.JoinGameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.JoinGameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGameId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlayerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string game_id = 1;
 * @return {string}
 */
proto.pictophone.v0_1.JoinGameRequest.prototype.getGameId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.pictophone.v0_1.JoinGameRequest.prototype.setGameId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string player_id = 2;
 * @return {string}
 */
proto.pictophone.v0_1.JoinGameRequest.prototype.getPlayerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.pictophone.v0_1.JoinGameRequest.prototype.setPlayerId = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pictophone.v0_1.JoinGameResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.pictophone.v0_1.JoinGameResponse.oneofGroups_);
};
goog.inherits(proto.pictophone.v0_1.JoinGameResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.pictophone.v0_1.JoinGameResponse.displayName = 'proto.pictophone.v0_1.JoinGameResponse';
}
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.pictophone.v0_1.JoinGameResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.pictophone.v0_1.JoinGameResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  UNKNOWN_ERROR: 1,
  GAME_ALREADY_STARTED_ERROR: 2
};

/**
 * @return {proto.pictophone.v0_1.JoinGameResponse.ErrorCase}
 */
proto.pictophone.v0_1.JoinGameResponse.prototype.getErrorCase = function() {
  return /** @type {proto.pictophone.v0_1.JoinGameResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.pictophone.v0_1.JoinGameResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pictophone.v0_1.JoinGameResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.pictophone.v0_1.JoinGameResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pictophone.v0_1.JoinGameResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.JoinGameResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    unknownError: (f = msg.getUnknownError()) && proto.pictophone.v0_1.UnknownError.toObject(includeInstance, f),
    gameAlreadyStartedError: (f = msg.getGameAlreadyStartedError()) && proto.pictophone.v0_1.GameAlreadyStartedError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pictophone.v0_1.JoinGameResponse}
 */
proto.pictophone.v0_1.JoinGameResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pictophone.v0_1.JoinGameResponse;
  return proto.pictophone.v0_1.JoinGameResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pictophone.v0_1.JoinGameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pictophone.v0_1.JoinGameResponse}
 */
proto.pictophone.v0_1.JoinGameResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pictophone.v0_1.UnknownError;
      reader.readMessage(value,proto.pictophone.v0_1.UnknownError.deserializeBinaryFromReader);
      msg.setUnknownError(value);
      break;
    case 2:
      var value = new proto.pictophone.v0_1.GameAlreadyStartedError;
      reader.readMessage(value,proto.pictophone.v0_1.GameAlreadyStartedError.deserializeBinaryFromReader);
      msg.setGameAlreadyStartedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pictophone.v0_1.JoinGameResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pictophone.v0_1.JoinGameResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pictophone.v0_1.JoinGameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.JoinGameResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnknownError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pictophone.v0_1.UnknownError.serializeBinaryToWriter
    );
  }
  f = message.getGameAlreadyStartedError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pictophone.v0_1.GameAlreadyStartedError.serializeBinaryToWriter
    );
  }
};


/**
 * optional UnknownError unknown_error = 1;
 * @return {?proto.pictophone.v0_1.UnknownError}
 */
proto.pictophone.v0_1.JoinGameResponse.prototype.getUnknownError = function() {
  return /** @type{?proto.pictophone.v0_1.UnknownError} */ (
    jspb.Message.getWrapperField(this, proto.pictophone.v0_1.UnknownError, 1));
};


/** @param {?proto.pictophone.v0_1.UnknownError|undefined} value */
proto.pictophone.v0_1.JoinGameResponse.prototype.setUnknownError = function(value) {
  jspb.Message.setOneofWrapperField(this, 1, proto.pictophone.v0_1.JoinGameResponse.oneofGroups_[0], value);
};


proto.pictophone.v0_1.JoinGameResponse.prototype.clearUnknownError = function() {
  this.setUnknownError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pictophone.v0_1.JoinGameResponse.prototype.hasUnknownError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GameAlreadyStartedError game_already_started_error = 2;
 * @return {?proto.pictophone.v0_1.GameAlreadyStartedError}
 */
proto.pictophone.v0_1.JoinGameResponse.prototype.getGameAlreadyStartedError = function() {
  return /** @type{?proto.pictophone.v0_1.GameAlreadyStartedError} */ (
    jspb.Message.getWrapperField(this, proto.pictophone.v0_1.GameAlreadyStartedError, 2));
};


/** @param {?proto.pictophone.v0_1.GameAlreadyStartedError|undefined} value */
proto.pictophone.v0_1.JoinGameResponse.prototype.setGameAlreadyStartedError = function(value) {
  jspb.Message.setOneofWrapperField(this, 2, proto.pictophone.v0_1.JoinGameResponse.oneofGroups_[0], value);
};


proto.pictophone.v0_1.JoinGameResponse.prototype.clearGameAlreadyStartedError = function() {
  this.setGameAlreadyStartedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pictophone.v0_1.JoinGameResponse.prototype.hasGameAlreadyStartedError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pictophone.v0_1.StartGameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pictophone.v0_1.StartGameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.pictophone.v0_1.StartGameRequest.displayName = 'proto.pictophone.v0_1.StartGameRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pictophone.v0_1.StartGameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.pictophone.v0_1.StartGameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pictophone.v0_1.StartGameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.StartGameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    gameId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    playerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    randomSeed: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pictophone.v0_1.StartGameRequest}
 */
proto.pictophone.v0_1.StartGameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pictophone.v0_1.StartGameRequest;
  return proto.pictophone.v0_1.StartGameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pictophone.v0_1.StartGameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pictophone.v0_1.StartGameRequest}
 */
proto.pictophone.v0_1.StartGameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRandomSeed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pictophone.v0_1.StartGameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pictophone.v0_1.StartGameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pictophone.v0_1.StartGameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.StartGameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGameId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlayerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRandomSeed();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
};


/**
 * optional string game_id = 1;
 * @return {string}
 */
proto.pictophone.v0_1.StartGameRequest.prototype.getGameId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.pictophone.v0_1.StartGameRequest.prototype.setGameId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string player_id = 2;
 * @return {string}
 */
proto.pictophone.v0_1.StartGameRequest.prototype.getPlayerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.pictophone.v0_1.StartGameRequest.prototype.setPlayerId = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 random_seed = 3;
 * @return {number}
 */
proto.pictophone.v0_1.StartGameRequest.prototype.getRandomSeed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.pictophone.v0_1.StartGameRequest.prototype.setRandomSeed = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pictophone.v0_1.StartGameResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.pictophone.v0_1.StartGameResponse.oneofGroups_);
};
goog.inherits(proto.pictophone.v0_1.StartGameResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.pictophone.v0_1.StartGameResponse.displayName = 'proto.pictophone.v0_1.StartGameResponse';
}
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.pictophone.v0_1.StartGameResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.pictophone.v0_1.StartGameResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  UNKNOWN_ERROR: 1,
  PLAYER_NOT_IN_GAME_ERROR: 2
};

/**
 * @return {proto.pictophone.v0_1.StartGameResponse.ErrorCase}
 */
proto.pictophone.v0_1.StartGameResponse.prototype.getErrorCase = function() {
  return /** @type {proto.pictophone.v0_1.StartGameResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.pictophone.v0_1.StartGameResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pictophone.v0_1.StartGameResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.pictophone.v0_1.StartGameResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pictophone.v0_1.StartGameResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.StartGameResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    unknownError: (f = msg.getUnknownError()) && proto.pictophone.v0_1.UnknownError.toObject(includeInstance, f),
    playerNotInGameError: (f = msg.getPlayerNotInGameError()) && proto.pictophone.v0_1.PlayerNotInGameError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pictophone.v0_1.StartGameResponse}
 */
proto.pictophone.v0_1.StartGameResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pictophone.v0_1.StartGameResponse;
  return proto.pictophone.v0_1.StartGameResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pictophone.v0_1.StartGameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pictophone.v0_1.StartGameResponse}
 */
proto.pictophone.v0_1.StartGameResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pictophone.v0_1.UnknownError;
      reader.readMessage(value,proto.pictophone.v0_1.UnknownError.deserializeBinaryFromReader);
      msg.setUnknownError(value);
      break;
    case 2:
      var value = new proto.pictophone.v0_1.PlayerNotInGameError;
      reader.readMessage(value,proto.pictophone.v0_1.PlayerNotInGameError.deserializeBinaryFromReader);
      msg.setPlayerNotInGameError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pictophone.v0_1.StartGameResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pictophone.v0_1.StartGameResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pictophone.v0_1.StartGameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.StartGameResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnknownError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pictophone.v0_1.UnknownError.serializeBinaryToWriter
    );
  }
  f = message.getPlayerNotInGameError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pictophone.v0_1.PlayerNotInGameError.serializeBinaryToWriter
    );
  }
};


/**
 * optional UnknownError unknown_error = 1;
 * @return {?proto.pictophone.v0_1.UnknownError}
 */
proto.pictophone.v0_1.StartGameResponse.prototype.getUnknownError = function() {
  return /** @type{?proto.pictophone.v0_1.UnknownError} */ (
    jspb.Message.getWrapperField(this, proto.pictophone.v0_1.UnknownError, 1));
};


/** @param {?proto.pictophone.v0_1.UnknownError|undefined} value */
proto.pictophone.v0_1.StartGameResponse.prototype.setUnknownError = function(value) {
  jspb.Message.setOneofWrapperField(this, 1, proto.pictophone.v0_1.StartGameResponse.oneofGroups_[0], value);
};


proto.pictophone.v0_1.StartGameResponse.prototype.clearUnknownError = function() {
  this.setUnknownError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pictophone.v0_1.StartGameResponse.prototype.hasUnknownError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PlayerNotInGameError player_not_in_game_error = 2;
 * @return {?proto.pictophone.v0_1.PlayerNotInGameError}
 */
proto.pictophone.v0_1.StartGameResponse.prototype.getPlayerNotInGameError = function() {
  return /** @type{?proto.pictophone.v0_1.PlayerNotInGameError} */ (
    jspb.Message.getWrapperField(this, proto.pictophone.v0_1.PlayerNotInGameError, 2));
};


/** @param {?proto.pictophone.v0_1.PlayerNotInGameError|undefined} value */
proto.pictophone.v0_1.StartGameResponse.prototype.setPlayerNotInGameError = function(value) {
  jspb.Message.setOneofWrapperField(this, 2, proto.pictophone.v0_1.StartGameResponse.oneofGroups_[0], value);
};


proto.pictophone.v0_1.StartGameResponse.prototype.clearPlayerNotInGameError = function() {
  this.setPlayerNotInGameError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pictophone.v0_1.StartGameResponse.prototype.hasPlayerNotInGameError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pictophone.v0_1.MakeMoveRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pictophone.v0_1.MakeMoveRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.pictophone.v0_1.MakeMoveRequest.displayName = 'proto.pictophone.v0_1.MakeMoveRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pictophone.v0_1.MakeMoveRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.pictophone.v0_1.MakeMoveRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pictophone.v0_1.MakeMoveRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.MakeMoveRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    gameId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    playerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    etag: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pictophone.v0_1.MakeMoveRequest}
 */
proto.pictophone.v0_1.MakeMoveRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pictophone.v0_1.MakeMoveRequest;
  return proto.pictophone.v0_1.MakeMoveRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pictophone.v0_1.MakeMoveRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pictophone.v0_1.MakeMoveRequest}
 */
proto.pictophone.v0_1.MakeMoveRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setEtag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pictophone.v0_1.MakeMoveRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pictophone.v0_1.MakeMoveRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pictophone.v0_1.MakeMoveRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.MakeMoveRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGameId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlayerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEtag();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
};


/**
 * optional string game_id = 1;
 * @return {string}
 */
proto.pictophone.v0_1.MakeMoveRequest.prototype.getGameId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.pictophone.v0_1.MakeMoveRequest.prototype.setGameId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string player_id = 2;
 * @return {string}
 */
proto.pictophone.v0_1.MakeMoveRequest.prototype.getPlayerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.pictophone.v0_1.MakeMoveRequest.prototype.setPlayerId = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 etag = 3;
 * @return {number}
 */
proto.pictophone.v0_1.MakeMoveRequest.prototype.getEtag = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.pictophone.v0_1.MakeMoveRequest.prototype.setEtag = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pictophone.v0_1.MakeMoveResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.pictophone.v0_1.MakeMoveResponse.oneofGroups_);
};
goog.inherits(proto.pictophone.v0_1.MakeMoveResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.pictophone.v0_1.MakeMoveResponse.displayName = 'proto.pictophone.v0_1.MakeMoveResponse';
}
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.pictophone.v0_1.MakeMoveResponse.oneofGroups_ = [[1,2,3,4,5]];

/**
 * @enum {number}
 */
proto.pictophone.v0_1.MakeMoveResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  UNKNOWN_ERROR: 1,
  MOVE_ABORTED_ERROR: 2,
  PLAYER_NOT_IN_GAME_ERROR: 3,
  GAME_NOT_STARTED_ERROR: 4,
  EMPTY_HAND_ERROR: 5
};

/**
 * @return {proto.pictophone.v0_1.MakeMoveResponse.ErrorCase}
 */
proto.pictophone.v0_1.MakeMoveResponse.prototype.getErrorCase = function() {
  return /** @type {proto.pictophone.v0_1.MakeMoveResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.pictophone.v0_1.MakeMoveResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pictophone.v0_1.MakeMoveResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.pictophone.v0_1.MakeMoveResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pictophone.v0_1.MakeMoveResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.MakeMoveResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    unknownError: (f = msg.getUnknownError()) && proto.pictophone.v0_1.UnknownError.toObject(includeInstance, f),
    moveAbortedError: (f = msg.getMoveAbortedError()) && proto.pictophone.v0_1.MoveAbortedError.toObject(includeInstance, f),
    playerNotInGameError: (f = msg.getPlayerNotInGameError()) && proto.pictophone.v0_1.PlayerNotInGameError.toObject(includeInstance, f),
    gameNotStartedError: (f = msg.getGameNotStartedError()) && proto.pictophone.v0_1.GameNotStartedError.toObject(includeInstance, f),
    emptyHandError: (f = msg.getEmptyHandError()) && proto.pictophone.v0_1.EmptyHandError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pictophone.v0_1.MakeMoveResponse}
 */
proto.pictophone.v0_1.MakeMoveResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pictophone.v0_1.MakeMoveResponse;
  return proto.pictophone.v0_1.MakeMoveResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pictophone.v0_1.MakeMoveResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pictophone.v0_1.MakeMoveResponse}
 */
proto.pictophone.v0_1.MakeMoveResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pictophone.v0_1.UnknownError;
      reader.readMessage(value,proto.pictophone.v0_1.UnknownError.deserializeBinaryFromReader);
      msg.setUnknownError(value);
      break;
    case 2:
      var value = new proto.pictophone.v0_1.MoveAbortedError;
      reader.readMessage(value,proto.pictophone.v0_1.MoveAbortedError.deserializeBinaryFromReader);
      msg.setMoveAbortedError(value);
      break;
    case 3:
      var value = new proto.pictophone.v0_1.PlayerNotInGameError;
      reader.readMessage(value,proto.pictophone.v0_1.PlayerNotInGameError.deserializeBinaryFromReader);
      msg.setPlayerNotInGameError(value);
      break;
    case 4:
      var value = new proto.pictophone.v0_1.GameNotStartedError;
      reader.readMessage(value,proto.pictophone.v0_1.GameNotStartedError.deserializeBinaryFromReader);
      msg.setGameNotStartedError(value);
      break;
    case 5:
      var value = new proto.pictophone.v0_1.EmptyHandError;
      reader.readMessage(value,proto.pictophone.v0_1.EmptyHandError.deserializeBinaryFromReader);
      msg.setEmptyHandError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pictophone.v0_1.MakeMoveResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pictophone.v0_1.MakeMoveResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pictophone.v0_1.MakeMoveResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.MakeMoveResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnknownError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pictophone.v0_1.UnknownError.serializeBinaryToWriter
    );
  }
  f = message.getMoveAbortedError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pictophone.v0_1.MoveAbortedError.serializeBinaryToWriter
    );
  }
  f = message.getPlayerNotInGameError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pictophone.v0_1.PlayerNotInGameError.serializeBinaryToWriter
    );
  }
  f = message.getGameNotStartedError();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.pictophone.v0_1.GameNotStartedError.serializeBinaryToWriter
    );
  }
  f = message.getEmptyHandError();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.pictophone.v0_1.EmptyHandError.serializeBinaryToWriter
    );
  }
};


/**
 * optional UnknownError unknown_error = 1;
 * @return {?proto.pictophone.v0_1.UnknownError}
 */
proto.pictophone.v0_1.MakeMoveResponse.prototype.getUnknownError = function() {
  return /** @type{?proto.pictophone.v0_1.UnknownError} */ (
    jspb.Message.getWrapperField(this, proto.pictophone.v0_1.UnknownError, 1));
};


/** @param {?proto.pictophone.v0_1.UnknownError|undefined} value */
proto.pictophone.v0_1.MakeMoveResponse.prototype.setUnknownError = function(value) {
  jspb.Message.setOneofWrapperField(this, 1, proto.pictophone.v0_1.MakeMoveResponse.oneofGroups_[0], value);
};


proto.pictophone.v0_1.MakeMoveResponse.prototype.clearUnknownError = function() {
  this.setUnknownError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pictophone.v0_1.MakeMoveResponse.prototype.hasUnknownError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MoveAbortedError move_aborted_error = 2;
 * @return {?proto.pictophone.v0_1.MoveAbortedError}
 */
proto.pictophone.v0_1.MakeMoveResponse.prototype.getMoveAbortedError = function() {
  return /** @type{?proto.pictophone.v0_1.MoveAbortedError} */ (
    jspb.Message.getWrapperField(this, proto.pictophone.v0_1.MoveAbortedError, 2));
};


/** @param {?proto.pictophone.v0_1.MoveAbortedError|undefined} value */
proto.pictophone.v0_1.MakeMoveResponse.prototype.setMoveAbortedError = function(value) {
  jspb.Message.setOneofWrapperField(this, 2, proto.pictophone.v0_1.MakeMoveResponse.oneofGroups_[0], value);
};


proto.pictophone.v0_1.MakeMoveResponse.prototype.clearMoveAbortedError = function() {
  this.setMoveAbortedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pictophone.v0_1.MakeMoveResponse.prototype.hasMoveAbortedError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PlayerNotInGameError player_not_in_game_error = 3;
 * @return {?proto.pictophone.v0_1.PlayerNotInGameError}
 */
proto.pictophone.v0_1.MakeMoveResponse.prototype.getPlayerNotInGameError = function() {
  return /** @type{?proto.pictophone.v0_1.PlayerNotInGameError} */ (
    jspb.Message.getWrapperField(this, proto.pictophone.v0_1.PlayerNotInGameError, 3));
};


/** @param {?proto.pictophone.v0_1.PlayerNotInGameError|undefined} value */
proto.pictophone.v0_1.MakeMoveResponse.prototype.setPlayerNotInGameError = function(value) {
  jspb.Message.setOneofWrapperField(this, 3, proto.pictophone.v0_1.MakeMoveResponse.oneofGroups_[0], value);
};


proto.pictophone.v0_1.MakeMoveResponse.prototype.clearPlayerNotInGameError = function() {
  this.setPlayerNotInGameError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pictophone.v0_1.MakeMoveResponse.prototype.hasPlayerNotInGameError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional GameNotStartedError game_not_started_error = 4;
 * @return {?proto.pictophone.v0_1.GameNotStartedError}
 */
proto.pictophone.v0_1.MakeMoveResponse.prototype.getGameNotStartedError = function() {
  return /** @type{?proto.pictophone.v0_1.GameNotStartedError} */ (
    jspb.Message.getWrapperField(this, proto.pictophone.v0_1.GameNotStartedError, 4));
};


/** @param {?proto.pictophone.v0_1.GameNotStartedError|undefined} value */
proto.pictophone.v0_1.MakeMoveResponse.prototype.setGameNotStartedError = function(value) {
  jspb.Message.setOneofWrapperField(this, 4, proto.pictophone.v0_1.MakeMoveResponse.oneofGroups_[0], value);
};


proto.pictophone.v0_1.MakeMoveResponse.prototype.clearGameNotStartedError = function() {
  this.setGameNotStartedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pictophone.v0_1.MakeMoveResponse.prototype.hasGameNotStartedError = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional EmptyHandError empty_hand_error = 5;
 * @return {?proto.pictophone.v0_1.EmptyHandError}
 */
proto.pictophone.v0_1.MakeMoveResponse.prototype.getEmptyHandError = function() {
  return /** @type{?proto.pictophone.v0_1.EmptyHandError} */ (
    jspb.Message.getWrapperField(this, proto.pictophone.v0_1.EmptyHandError, 5));
};


/** @param {?proto.pictophone.v0_1.EmptyHandError|undefined} value */
proto.pictophone.v0_1.MakeMoveResponse.prototype.setEmptyHandError = function(value) {
  jspb.Message.setOneofWrapperField(this, 5, proto.pictophone.v0_1.MakeMoveResponse.oneofGroups_[0], value);
};


proto.pictophone.v0_1.MakeMoveResponse.prototype.clearEmptyHandError = function() {
  this.setEmptyHandError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pictophone.v0_1.MakeMoveResponse.prototype.hasEmptyHandError = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pictophone.v0_1.GetGameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pictophone.v0_1.GetGameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.pictophone.v0_1.GetGameRequest.displayName = 'proto.pictophone.v0_1.GetGameRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pictophone.v0_1.GetGameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.pictophone.v0_1.GetGameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pictophone.v0_1.GetGameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.GetGameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    gameId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    playerId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pictophone.v0_1.GetGameRequest}
 */
proto.pictophone.v0_1.GetGameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pictophone.v0_1.GetGameRequest;
  return proto.pictophone.v0_1.GetGameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pictophone.v0_1.GetGameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pictophone.v0_1.GetGameRequest}
 */
proto.pictophone.v0_1.GetGameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pictophone.v0_1.GetGameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pictophone.v0_1.GetGameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pictophone.v0_1.GetGameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.GetGameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGameId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlayerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string game_id = 1;
 * @return {string}
 */
proto.pictophone.v0_1.GetGameRequest.prototype.getGameId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.pictophone.v0_1.GetGameRequest.prototype.setGameId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string player_id = 2;
 * @return {string}
 */
proto.pictophone.v0_1.GetGameRequest.prototype.getPlayerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.pictophone.v0_1.GetGameRequest.prototype.setPlayerId = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pictophone.v0_1.GetGameResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.pictophone.v0_1.GetGameResponse.oneofGroups_);
};
goog.inherits(proto.pictophone.v0_1.GetGameResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.pictophone.v0_1.GetGameResponse.displayName = 'proto.pictophone.v0_1.GetGameResponse';
}
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.pictophone.v0_1.GetGameResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.pictophone.v0_1.GetGameResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  UNKNOWN_ERROR: 1,
  PLAYER_NOT_IN_GAME_ERROR: 2
};

/**
 * @return {proto.pictophone.v0_1.GetGameResponse.ErrorCase}
 */
proto.pictophone.v0_1.GetGameResponse.prototype.getErrorCase = function() {
  return /** @type {proto.pictophone.v0_1.GetGameResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.pictophone.v0_1.GetGameResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pictophone.v0_1.GetGameResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.pictophone.v0_1.GetGameResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pictophone.v0_1.GetGameResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.GetGameResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    unknownError: (f = msg.getUnknownError()) && proto.pictophone.v0_1.UnknownError.toObject(includeInstance, f),
    playerNotInGameError: (f = msg.getPlayerNotInGameError()) && proto.pictophone.v0_1.PlayerNotInGameError.toObject(includeInstance, f),
    game: (f = msg.getGame()) && proto.pictophone.v0_1.Game.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pictophone.v0_1.GetGameResponse}
 */
proto.pictophone.v0_1.GetGameResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pictophone.v0_1.GetGameResponse;
  return proto.pictophone.v0_1.GetGameResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pictophone.v0_1.GetGameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pictophone.v0_1.GetGameResponse}
 */
proto.pictophone.v0_1.GetGameResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pictophone.v0_1.UnknownError;
      reader.readMessage(value,proto.pictophone.v0_1.UnknownError.deserializeBinaryFromReader);
      msg.setUnknownError(value);
      break;
    case 2:
      var value = new proto.pictophone.v0_1.PlayerNotInGameError;
      reader.readMessage(value,proto.pictophone.v0_1.PlayerNotInGameError.deserializeBinaryFromReader);
      msg.setPlayerNotInGameError(value);
      break;
    case 3:
      var value = new proto.pictophone.v0_1.Game;
      reader.readMessage(value,proto.pictophone.v0_1.Game.deserializeBinaryFromReader);
      msg.setGame(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pictophone.v0_1.GetGameResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pictophone.v0_1.GetGameResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pictophone.v0_1.GetGameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.GetGameResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnknownError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pictophone.v0_1.UnknownError.serializeBinaryToWriter
    );
  }
  f = message.getPlayerNotInGameError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pictophone.v0_1.PlayerNotInGameError.serializeBinaryToWriter
    );
  }
  f = message.getGame();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pictophone.v0_1.Game.serializeBinaryToWriter
    );
  }
};


/**
 * optional UnknownError unknown_error = 1;
 * @return {?proto.pictophone.v0_1.UnknownError}
 */
proto.pictophone.v0_1.GetGameResponse.prototype.getUnknownError = function() {
  return /** @type{?proto.pictophone.v0_1.UnknownError} */ (
    jspb.Message.getWrapperField(this, proto.pictophone.v0_1.UnknownError, 1));
};


/** @param {?proto.pictophone.v0_1.UnknownError|undefined} value */
proto.pictophone.v0_1.GetGameResponse.prototype.setUnknownError = function(value) {
  jspb.Message.setOneofWrapperField(this, 1, proto.pictophone.v0_1.GetGameResponse.oneofGroups_[0], value);
};


proto.pictophone.v0_1.GetGameResponse.prototype.clearUnknownError = function() {
  this.setUnknownError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pictophone.v0_1.GetGameResponse.prototype.hasUnknownError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PlayerNotInGameError player_not_in_game_error = 2;
 * @return {?proto.pictophone.v0_1.PlayerNotInGameError}
 */
proto.pictophone.v0_1.GetGameResponse.prototype.getPlayerNotInGameError = function() {
  return /** @type{?proto.pictophone.v0_1.PlayerNotInGameError} */ (
    jspb.Message.getWrapperField(this, proto.pictophone.v0_1.PlayerNotInGameError, 2));
};


/** @param {?proto.pictophone.v0_1.PlayerNotInGameError|undefined} value */
proto.pictophone.v0_1.GetGameResponse.prototype.setPlayerNotInGameError = function(value) {
  jspb.Message.setOneofWrapperField(this, 2, proto.pictophone.v0_1.GetGameResponse.oneofGroups_[0], value);
};


proto.pictophone.v0_1.GetGameResponse.prototype.clearPlayerNotInGameError = function() {
  this.setPlayerNotInGameError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pictophone.v0_1.GetGameResponse.prototype.hasPlayerNotInGameError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Game game = 3;
 * @return {?proto.pictophone.v0_1.Game}
 */
proto.pictophone.v0_1.GetGameResponse.prototype.getGame = function() {
  return /** @type{?proto.pictophone.v0_1.Game} */ (
    jspb.Message.getWrapperField(this, proto.pictophone.v0_1.Game, 3));
};


/** @param {?proto.pictophone.v0_1.Game|undefined} value */
proto.pictophone.v0_1.GetGameResponse.prototype.setGame = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.pictophone.v0_1.GetGameResponse.prototype.clearGame = function() {
  this.setGame(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pictophone.v0_1.GetGameResponse.prototype.hasGame = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pictophone.v0_1.Game = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pictophone.v0_1.Game.repeatedFields_, proto.pictophone.v0_1.Game.oneofGroups_);
};
goog.inherits(proto.pictophone.v0_1.Game, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.pictophone.v0_1.Game.displayName = 'proto.pictophone.v0_1.Game';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pictophone.v0_1.Game.repeatedFields_ = [1];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.pictophone.v0_1.Game.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.pictophone.v0_1.Game.StateCase = {
  STATE_NOT_SET: 0,
  UNSTARTED: 2,
  STARTED: 3
};

/**
 * @return {proto.pictophone.v0_1.Game.StateCase}
 */
proto.pictophone.v0_1.Game.prototype.getStateCase = function() {
  return /** @type {proto.pictophone.v0_1.Game.StateCase} */(jspb.Message.computeOneofCase(this, proto.pictophone.v0_1.Game.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pictophone.v0_1.Game.prototype.toObject = function(opt_includeInstance) {
  return proto.pictophone.v0_1.Game.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pictophone.v0_1.Game} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.Game.toObject = function(includeInstance, msg) {
  var f, obj = {
    playerIdsList: jspb.Message.getRepeatedField(msg, 1),
    unstarted: (f = msg.getUnstarted()) && proto.pictophone.v0_1.Game.Unstarted.toObject(includeInstance, f),
    started: (f = msg.getStarted()) && proto.pictophone.v0_1.Game.Started.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pictophone.v0_1.Game}
 */
proto.pictophone.v0_1.Game.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pictophone.v0_1.Game;
  return proto.pictophone.v0_1.Game.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pictophone.v0_1.Game} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pictophone.v0_1.Game}
 */
proto.pictophone.v0_1.Game.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addPlayerIds(value);
      break;
    case 2:
      var value = new proto.pictophone.v0_1.Game.Unstarted;
      reader.readMessage(value,proto.pictophone.v0_1.Game.Unstarted.deserializeBinaryFromReader);
      msg.setUnstarted(value);
      break;
    case 3:
      var value = new proto.pictophone.v0_1.Game.Started;
      reader.readMessage(value,proto.pictophone.v0_1.Game.Started.deserializeBinaryFromReader);
      msg.setStarted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pictophone.v0_1.Game.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pictophone.v0_1.Game.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pictophone.v0_1.Game} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.Game.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayerIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getUnstarted();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pictophone.v0_1.Game.Unstarted.serializeBinaryToWriter
    );
  }
  f = message.getStarted();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pictophone.v0_1.Game.Started.serializeBinaryToWriter
    );
  }
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pictophone.v0_1.Game.Unstarted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pictophone.v0_1.Game.Unstarted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.pictophone.v0_1.Game.Unstarted.displayName = 'proto.pictophone.v0_1.Game.Unstarted';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pictophone.v0_1.Game.Unstarted.prototype.toObject = function(opt_includeInstance) {
  return proto.pictophone.v0_1.Game.Unstarted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pictophone.v0_1.Game.Unstarted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.Game.Unstarted.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pictophone.v0_1.Game.Unstarted}
 */
proto.pictophone.v0_1.Game.Unstarted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pictophone.v0_1.Game.Unstarted;
  return proto.pictophone.v0_1.Game.Unstarted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pictophone.v0_1.Game.Unstarted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pictophone.v0_1.Game.Unstarted}
 */
proto.pictophone.v0_1.Game.Unstarted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pictophone.v0_1.Game.Unstarted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pictophone.v0_1.Game.Unstarted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pictophone.v0_1.Game.Unstarted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.Game.Unstarted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pictophone.v0_1.Game.Started = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pictophone.v0_1.Game.Started.repeatedFields_, null);
};
goog.inherits(proto.pictophone.v0_1.Game.Started, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.pictophone.v0_1.Game.Started.displayName = 'proto.pictophone.v0_1.Game.Started';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pictophone.v0_1.Game.Started.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pictophone.v0_1.Game.Started.prototype.toObject = function(opt_includeInstance) {
  return proto.pictophone.v0_1.Game.Started.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pictophone.v0_1.Game.Started} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.Game.Started.toObject = function(includeInstance, msg) {
  var f, obj = {
    etag: jspb.Message.getFieldWithDefault(msg, 1, 0),
    numMistakes: jspb.Message.getFieldWithDefault(msg, 2, 0),
    roundNum: jspb.Message.getFieldWithDefault(msg, 3, 0),
    numbersPlayedList: jspb.Message.getRepeatedField(msg, 4),
    handList: jspb.Message.getRepeatedField(msg, 5)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pictophone.v0_1.Game.Started}
 */
proto.pictophone.v0_1.Game.Started.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pictophone.v0_1.Game.Started;
  return proto.pictophone.v0_1.Game.Started.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pictophone.v0_1.Game.Started} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pictophone.v0_1.Game.Started}
 */
proto.pictophone.v0_1.Game.Started.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setEtag(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumMistakes(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRoundNum(value);
      break;
    case 4:
      var value = /** @type {!Array<number>} */ (reader.readPackedUint32());
      msg.setNumbersPlayedList(value);
      break;
    case 5:
      var value = /** @type {!Array<number>} */ (reader.readPackedUint32());
      msg.setHandList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pictophone.v0_1.Game.Started.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pictophone.v0_1.Game.Started.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pictophone.v0_1.Game.Started} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.Game.Started.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEtag();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getNumMistakes();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getRoundNum();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getNumbersPlayedList();
  if (f.length > 0) {
    writer.writePackedUint32(
      4,
      f
    );
  }
  f = message.getHandList();
  if (f.length > 0) {
    writer.writePackedUint32(
      5,
      f
    );
  }
};


/**
 * optional uint64 etag = 1;
 * @return {number}
 */
proto.pictophone.v0_1.Game.Started.prototype.getEtag = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.pictophone.v0_1.Game.Started.prototype.setEtag = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 num_mistakes = 2;
 * @return {number}
 */
proto.pictophone.v0_1.Game.Started.prototype.getNumMistakes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.pictophone.v0_1.Game.Started.prototype.setNumMistakes = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 round_num = 3;
 * @return {number}
 */
proto.pictophone.v0_1.Game.Started.prototype.getRoundNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.pictophone.v0_1.Game.Started.prototype.setRoundNum = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated uint32 numbers_played = 4;
 * @return {!Array<number>}
 */
proto.pictophone.v0_1.Game.Started.prototype.getNumbersPlayedList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/** @param {!Array<number>} value */
proto.pictophone.v0_1.Game.Started.prototype.setNumbersPlayedList = function(value) {
  jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.pictophone.v0_1.Game.Started.prototype.addNumbersPlayed = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


proto.pictophone.v0_1.Game.Started.prototype.clearNumbersPlayedList = function() {
  this.setNumbersPlayedList([]);
};


/**
 * repeated uint32 hand = 5;
 * @return {!Array<number>}
 */
proto.pictophone.v0_1.Game.Started.prototype.getHandList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/** @param {!Array<number>} value */
proto.pictophone.v0_1.Game.Started.prototype.setHandList = function(value) {
  jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.pictophone.v0_1.Game.Started.prototype.addHand = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


proto.pictophone.v0_1.Game.Started.prototype.clearHandList = function() {
  this.setHandList([]);
};


/**
 * repeated string player_ids = 1;
 * @return {!Array<string>}
 */
proto.pictophone.v0_1.Game.prototype.getPlayerIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<string>} value */
proto.pictophone.v0_1.Game.prototype.setPlayerIdsList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.pictophone.v0_1.Game.prototype.addPlayerIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.pictophone.v0_1.Game.prototype.clearPlayerIdsList = function() {
  this.setPlayerIdsList([]);
};


/**
 * optional Unstarted unstarted = 2;
 * @return {?proto.pictophone.v0_1.Game.Unstarted}
 */
proto.pictophone.v0_1.Game.prototype.getUnstarted = function() {
  return /** @type{?proto.pictophone.v0_1.Game.Unstarted} */ (
    jspb.Message.getWrapperField(this, proto.pictophone.v0_1.Game.Unstarted, 2));
};


/** @param {?proto.pictophone.v0_1.Game.Unstarted|undefined} value */
proto.pictophone.v0_1.Game.prototype.setUnstarted = function(value) {
  jspb.Message.setOneofWrapperField(this, 2, proto.pictophone.v0_1.Game.oneofGroups_[0], value);
};


proto.pictophone.v0_1.Game.prototype.clearUnstarted = function() {
  this.setUnstarted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pictophone.v0_1.Game.prototype.hasUnstarted = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Started started = 3;
 * @return {?proto.pictophone.v0_1.Game.Started}
 */
proto.pictophone.v0_1.Game.prototype.getStarted = function() {
  return /** @type{?proto.pictophone.v0_1.Game.Started} */ (
    jspb.Message.getWrapperField(this, proto.pictophone.v0_1.Game.Started, 3));
};


/** @param {?proto.pictophone.v0_1.Game.Started|undefined} value */
proto.pictophone.v0_1.Game.prototype.setStarted = function(value) {
  jspb.Message.setOneofWrapperField(this, 3, proto.pictophone.v0_1.Game.oneofGroups_[0], value);
};


proto.pictophone.v0_1.Game.prototype.clearStarted = function() {
  this.setStarted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pictophone.v0_1.Game.prototype.hasStarted = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pictophone.v0_1.UnknownError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pictophone.v0_1.UnknownError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.pictophone.v0_1.UnknownError.displayName = 'proto.pictophone.v0_1.UnknownError';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pictophone.v0_1.UnknownError.prototype.toObject = function(opt_includeInstance) {
  return proto.pictophone.v0_1.UnknownError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pictophone.v0_1.UnknownError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.UnknownError.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pictophone.v0_1.UnknownError}
 */
proto.pictophone.v0_1.UnknownError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pictophone.v0_1.UnknownError;
  return proto.pictophone.v0_1.UnknownError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pictophone.v0_1.UnknownError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pictophone.v0_1.UnknownError}
 */
proto.pictophone.v0_1.UnknownError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pictophone.v0_1.UnknownError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pictophone.v0_1.UnknownError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pictophone.v0_1.UnknownError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.UnknownError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string error = 1;
 * @return {string}
 */
proto.pictophone.v0_1.UnknownError.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.pictophone.v0_1.UnknownError.prototype.setError = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pictophone.v0_1.GameAlreadyStartedError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pictophone.v0_1.GameAlreadyStartedError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.pictophone.v0_1.GameAlreadyStartedError.displayName = 'proto.pictophone.v0_1.GameAlreadyStartedError';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pictophone.v0_1.GameAlreadyStartedError.prototype.toObject = function(opt_includeInstance) {
  return proto.pictophone.v0_1.GameAlreadyStartedError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pictophone.v0_1.GameAlreadyStartedError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.GameAlreadyStartedError.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pictophone.v0_1.GameAlreadyStartedError}
 */
proto.pictophone.v0_1.GameAlreadyStartedError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pictophone.v0_1.GameAlreadyStartedError;
  return proto.pictophone.v0_1.GameAlreadyStartedError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pictophone.v0_1.GameAlreadyStartedError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pictophone.v0_1.GameAlreadyStartedError}
 */
proto.pictophone.v0_1.GameAlreadyStartedError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pictophone.v0_1.GameAlreadyStartedError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pictophone.v0_1.GameAlreadyStartedError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pictophone.v0_1.GameAlreadyStartedError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.GameAlreadyStartedError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pictophone.v0_1.GameNotStartedError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pictophone.v0_1.GameNotStartedError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.pictophone.v0_1.GameNotStartedError.displayName = 'proto.pictophone.v0_1.GameNotStartedError';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pictophone.v0_1.GameNotStartedError.prototype.toObject = function(opt_includeInstance) {
  return proto.pictophone.v0_1.GameNotStartedError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pictophone.v0_1.GameNotStartedError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.GameNotStartedError.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pictophone.v0_1.GameNotStartedError}
 */
proto.pictophone.v0_1.GameNotStartedError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pictophone.v0_1.GameNotStartedError;
  return proto.pictophone.v0_1.GameNotStartedError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pictophone.v0_1.GameNotStartedError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pictophone.v0_1.GameNotStartedError}
 */
proto.pictophone.v0_1.GameNotStartedError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pictophone.v0_1.GameNotStartedError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pictophone.v0_1.GameNotStartedError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pictophone.v0_1.GameNotStartedError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.GameNotStartedError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pictophone.v0_1.PlayerNotInGameError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pictophone.v0_1.PlayerNotInGameError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.pictophone.v0_1.PlayerNotInGameError.displayName = 'proto.pictophone.v0_1.PlayerNotInGameError';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pictophone.v0_1.PlayerNotInGameError.prototype.toObject = function(opt_includeInstance) {
  return proto.pictophone.v0_1.PlayerNotInGameError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pictophone.v0_1.PlayerNotInGameError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.PlayerNotInGameError.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pictophone.v0_1.PlayerNotInGameError}
 */
proto.pictophone.v0_1.PlayerNotInGameError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pictophone.v0_1.PlayerNotInGameError;
  return proto.pictophone.v0_1.PlayerNotInGameError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pictophone.v0_1.PlayerNotInGameError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pictophone.v0_1.PlayerNotInGameError}
 */
proto.pictophone.v0_1.PlayerNotInGameError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pictophone.v0_1.PlayerNotInGameError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pictophone.v0_1.PlayerNotInGameError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pictophone.v0_1.PlayerNotInGameError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.PlayerNotInGameError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pictophone.v0_1.MoveAbortedError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pictophone.v0_1.MoveAbortedError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.pictophone.v0_1.MoveAbortedError.displayName = 'proto.pictophone.v0_1.MoveAbortedError';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pictophone.v0_1.MoveAbortedError.prototype.toObject = function(opt_includeInstance) {
  return proto.pictophone.v0_1.MoveAbortedError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pictophone.v0_1.MoveAbortedError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.MoveAbortedError.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pictophone.v0_1.MoveAbortedError}
 */
proto.pictophone.v0_1.MoveAbortedError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pictophone.v0_1.MoveAbortedError;
  return proto.pictophone.v0_1.MoveAbortedError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pictophone.v0_1.MoveAbortedError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pictophone.v0_1.MoveAbortedError}
 */
proto.pictophone.v0_1.MoveAbortedError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pictophone.v0_1.MoveAbortedError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pictophone.v0_1.MoveAbortedError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pictophone.v0_1.MoveAbortedError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.MoveAbortedError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pictophone.v0_1.EmptyHandError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pictophone.v0_1.EmptyHandError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.pictophone.v0_1.EmptyHandError.displayName = 'proto.pictophone.v0_1.EmptyHandError';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pictophone.v0_1.EmptyHandError.prototype.toObject = function(opt_includeInstance) {
  return proto.pictophone.v0_1.EmptyHandError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pictophone.v0_1.EmptyHandError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.EmptyHandError.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pictophone.v0_1.EmptyHandError}
 */
proto.pictophone.v0_1.EmptyHandError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pictophone.v0_1.EmptyHandError;
  return proto.pictophone.v0_1.EmptyHandError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pictophone.v0_1.EmptyHandError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pictophone.v0_1.EmptyHandError}
 */
proto.pictophone.v0_1.EmptyHandError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pictophone.v0_1.EmptyHandError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pictophone.v0_1.EmptyHandError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pictophone.v0_1.EmptyHandError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pictophone.v0_1.EmptyHandError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.pictophone.v0_1);
